<script>
export default {
  metaInfo: {
    title: 'Спасибо за оплату ХаниМани',
    meta: [
      { name: 'robots', content: 'noindex' }
    ]
  }
}
</script>

<template lang="pug">
div
  section.hero.is-success(style='margin-top: 3.25rem;')
    .hero-body
      .container.content
        .title.is-1 Деньги пришли. Спасибо!
  section.section
    .container
      .columns
        .column.is-10.is-offset-1
          .box.has-text-centered
            p
              | При оплате через Яндекс.Деньги доступ будет продлён
              strong  в течение 1–2 дней.
            //- p
            //-   | При оплате банковской картой продление доступа происходит автоматически.
            //-   br
            //-   | В выписке по карте платёж будет с описанием «Edmonton HoneyMoney».
            br
            .title.is-3
              | Приложите руку к экрану
              br
              | и
              br
              | дайте пять!
            img(width='400', src='@/assets/bees/high_five.png')
            .title.is-5 Вы делаете нас счастливыми пчёлами :)
</template>
